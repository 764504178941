import {Link, useLocation} from "react-router-dom";
import {FaArrowLeft} from "react-icons/fa";
import React from "react";

export default  function  BarraAreaAluno(props){

    const location = useLocation();
    const pathParts = location.pathname.split('/');
    const entidade = pathParts[1]
    
    return(
        <div className="nomeAluno">
            <div className="nome">
                <span style={{textTransform: 'uppercase' }}> {props.nome} </span>
            </div>
            <div className="seta">
                <Link to={`/${entidade}/`} >
                    <FaArrowLeft/>
                </Link>
            </div>
        </div>
    );
}