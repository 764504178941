import React, { useEffect, useState } from 'react';
import '../../../scss/_areaAluno.scss'
import { Link, useLocation } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import TabelaAluno from "./Components/TabelaAluno";
import Barra from "./Components/BarraAreaAluno"
import FotoAluno from "./Components/FotoAluno";
import axios from "axios";

const AreaAluno = () => {

    const location = useLocation();
    const pathParts = location.pathname.split('/');
    const numeroAluno = pathParts[3]
    const entidade = pathParts[1]
    const [alunoInfo, setAlunoInfo] = useState();
    const [dadosAluno, setDadosAluno] = useState([])

    const columns = [
        { header: 'Escolas', key: 'escola' },
        { header: 'Ano', key: 'ano' },
        { header: 'Nivel', key: 'nivel' },
        { header: 'Turma', key: 'turma' },
    ];


    const fetchInfoAluno = async () => {
        const url = `/api/area-aluno/listar`;

        try {
            const response = await axios.get(url, {
                params: {
                    numeroAluno: numeroAluno,
                    entidadeUrl: entidade
                }
            });

            setDadosAluno(response.data)
        } catch (error) {
            console.error('Não foi possível carregar as informações do aluno');
        }
    }

    const fetchNomeAluno = async () => {
        const url = `/api/area-aluno/nomeAluno`;

        try {
            const response = await axios.get(url, {
                params: {
                    numeroAluno: numeroAluno,
                    entidadeUrl: entidade
                }
            });

            setAlunoInfo((response.data))
            localStorage.setItem('nome', response.data.nome_completo);


        } catch (error) {
            console.error('Error ao fazer a requisição GET', error);
        }
    }

    useEffect(() => {
        fetchInfoAluno()
        fetchNomeAluno();
    }, [numeroAluno]);
    
    return (
        <div className="detalheAluno">

            <div className="titulo">
                <h1> ÁREA DO ALUNO</h1>
            </div>
            {alunoInfo &&
                <Barra nome={alunoInfo.nome_completo}></Barra>
            }
            <div className="info">
                {alunoInfo &&
                    <FotoAluno foto={alunoInfo.foto} nome={alunoInfo.nome_completo} numero={numeroAluno}></FotoAluno>
                }
                <div className="tabela">
                    {dadosAluno.length > 0 &&
                        <TabelaAluno columns={columns} data={dadosAluno}></TabelaAluno>
                    }
                </div>
            </div>
        </div>
    );

};

export default AreaAluno;
